<template>
  <div class="app">
    <div class="card">
      <template v-if="loginType === 'normal'">
        <LoginForm @toggle="toggle" />
      </template>
      <template v-else-if="loginType === 'forgot-password'">
        <ForgotPassword @toggle="toggle" />
      </template>
      <template v-else>
        <FindPassword @toggle="toggle" />
      </template>
    </div>
  </div>
</template>

<script>
import LoginForm from "./login-form";
import ForgotPassword from "./forgot-password";
import FindPassword from "./find-password";

export default {
  components: {
    LoginForm,
    ForgotPassword,
    FindPassword
  },
  data() {
    return {
      loginType: "normal"
    };
  },

  mounted() {
    window.localStorage.clear();
  },

  methods: {
    toggle(loginType) {
      this.loginType = loginType;
    }
  }
};
</script>

<style lang="less" scoped>
.app {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../../assets/bg.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .card {
    width: 420px;
    background: #fff;
    box-shadow: 0px 4px 17px 0px rgba(37, 72, 110, 0.18);
    border-radius: 12px;
    padding: 30px;
    box-sizing: border-box;
  }
}
</style>

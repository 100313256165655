<template>
  <div>
    <div class="header">
      <div class="title">登录</div>
      <!-- @click="$router.push('/register')" -->
      <div class="extra">
        <span>注册</span>
        <a-icon type="right-circle" style="margin-top: 1px" />
      </div>
    </div>

    <div class="item">
      <label for="mobile">手机号码</label>
      <input
        id="mobile"
        type="text"
        placeholder="请输入手机号码"
        v-model="mobile"
      />
    </div>
    <div class="item">
      <label for="password">登录密码</label>
      <input
        id="password"
        type="password"
        placeholder="请输入登录密码"
        v-model="password"
      />
    </div>
    <div class="forgot" @click.prevent="forgotPassword">忘记密码？</div>

    <button class="primary-button" @click="onClick">登录</button>

    <!-- <a-button
      :loading="loading"
      style="width: 100%; margin-top: 24px"
      size="large"
      type="primary"
      html-type="submit"
    >登录</a-button>-->
  </div>
</template>

<script>
import { login } from "@/api/login";
export default {
  props: {
    redirect: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      mobile: "",
      password: "",
      loading: false,
    };
  },

  methods: {
    onClick() {
      if (!this.mobile) {
        this.$message.error("请输入手机号码");
        return;
      }
      if (!this.password) {
        this.$message.error("请输入登录密码");
        return;
      }

      login({
        mobile: this.mobile,
        password: this.$encrypt.encrypt(this.password),
      }).then((res) => {
        console.log("login res", res);
        if (res && res.token) {
          window.localStorage.setItem("token", res.token);
          // window.localStorage.setItem('user', JSON.stringify(res.user))
          window.localStorage.setItem("name", res.user.name);
          window.localStorage.setItem("mobile", res.user.mobile);
          window.localStorage.setItem("email", res.user.email || "");
          window.localStorage.setItem("avatar", res.user.header || "");
          this.$router.push("/");
        }
      });
    },

    forgotPassword() {
      // this.$emit("toggle", "forgot-password");
    },
  },
};
</script>

<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: #0560fd;
    font-size: 24px;
    font-weight: 500;
  }

  .extra {
    color: #999;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}

.item {
  border-bottom: 1px solid #f0f0f0;
  margin-top: 32px;
  font-size: 16px;
  line-height: 3em;
  display: flex;
  align-items: center;
}
#mobile,
#password {
  flex: 1;
  font-size: 16px;
  line-height: 1;
}

label {
  font-weight: 500;
  margin-right: 24px;
}

.forgot {
  margin-top: 24px;
  text-align: right;
  cursor: pointer;
}

.primary-button {
  color: #fff;
  margin-top: 40px;
  font-size: 16px;
  line-height: 2.8;
  display: block;
  width: 100%;
  background: linear-gradient(146deg, #0c99ff 0%, #0560fd 100%);
  box-shadow: 0px 5px 8px 0px rgba(5, 96, 253, 0.32);
  border-radius: 6px;
}
</style>
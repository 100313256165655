<template>
  <div>
    <div class="header">
      <div class="title">忘记密码</div>
      <div class="extra" @click="$router.push('/register')">
        <span>注册</span>
        <a-icon type="right-circle" style="margin-top: 1px" />
      </div>
    </div>

    <a-form :form="form" @submit="handleSubmit">
      <a-form-item>
        <a-input
          v-decorator="['mobile', { rules: [{ validator }] }]"
          size="large"
          placeholder="请输入手机号"
        >
          <a-icon slot="prefix" type="mobile" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-row :gutter="8">
          <a-col span="16">
            <a-input
              v-decorator="[
                'code',
                { rules: [{ required: true, message: '请输入！' }] },
              ]"
              size="large"
              placeholder="请输入验证码"
            >
              <a-icon slot="prefix" type="safety-certificate" />
            </a-input>
          </a-col>
          <a-col span="8">
            <a-button
              type="primary"
              size="large"
              block
              @click="getCode"
              :loading="getCodeLoading"
              :disabled="second > 0"
              >{{ second > 0 ? `重新获取(${second})` : "获取验证码" }}</a-button
            >
          </a-col>
        </a-row>
      </a-form-item>

      <div class="back left" @click="back">
        <a-icon type="left" style="margin-right: 2px" />
        <span>返回上层</span>
      </div>

      <a-button
        style="margin-top: 24px"
        size="large"
        type="primary"
        block
        html-type="submit"
        :loading="nextLoading"
        >下一步</a-button
      >
    </a-form>
  </div>
</template>

<script>
// import { fetchCode, validateCode } from "@/api/login";
// import { mapMutations } from "vuex";

const patt = /^1\d{10}$/;

export default {
  data() {
    return {
      form: this.$form.createForm(this),

      getCodeLoading: false, // 获取验证码 loading
      second: 0, // 倒计时

      nextLoading: false, // 校验验证码 loading
    };
  },

  mounted() {
    // 用户重置页面，如果 second 的倒计时还没结束，那么恢复倒计时
    const second = window.localStorage.getItem("second");
    if (Number(second) > 0) {
      this.setSecond(second);
      this.form.setFieldsValue({
        mobile: window.localStorage.getItem("mobile"),
      });
    }
  },

  methods: {
    // 自定义校验规则
    validator(rule, value) {
      let testResult = patt.test(value);
      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入！");
        } else if (!testResult) {
          reject("手机号格式不正确！");
        } else {
          resolve();
        }
      });
    },

    getCode() {
      // const mobile = this.form.getFieldValue("mobile");
      // let testResult = patt.test(mobile);
      // if (testResult) {
      //   this.getCodeLoading = true;
      //   fetchCode({
      //     mobile
      //   })
      //     .then(() => {
      //       this.$message.success("发送成功，请注意查收！");
      //       this.setSecond(300);
      //       window.localStorage.setItem("mobile", mobile);
      //     })
      //     .finally(() => {
      //       this.getCodeLoading = false;
      //     });
      // } else {
      //   this.$message.error("请输入正确的手机号！");
      // }
    },

    setSecond(value) {
      this.second = value;
      const timer = setInterval(() => {
        if (this.second <= 0) {
          clearInterval(timer);
        }
        this.second--;
        window.localStorage.setItem("second", this.second);
      }, 1000);
      this.$once("hook:beforeDestroy", () => {
        if (timer) {
          clearInterval(timer);
        }
      });
    },

    // ...mapMutations("login", ["setUser"]),

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
          // this.nextLoading = true;
          // validateCode({
          //   ...values
          // })
          //   .then(res => {
          //     if (res) {
          //       this.setUser(res);
          //       this.$message.success("请输入新密码！");
          //       this.$emit("toggle", "find-password");
          //     }
          //   })
          //   .finally(() => {
          //     this.nextLoading = false;
          //   });
        }
      });
    },

    back() {
      this.$emit("toggle", "normal");
    },
  },
};
</script>
<style lang="less" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    color: #0560fd;
    font-size: 24px;
    font-weight: 500;
  }

  .extra {
    color: #999;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
  }
}

.back {
  // margin-top: 12px;
  cursor: pointer;
  color: #fff;
}
</style>
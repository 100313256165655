<template>
  <a-form :form="form" @submit="handleSubmit">
    <a-form-item>
      <a-input
        type="password"
        v-decorator="['password', { rules: [{ validator: validator1 }] }]"
        size="large"
        placeholder="请输入新密码"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>
    <a-form-item>
      <a-input
        type="password"
        v-decorator="['password1', { rules: [{ validator: validator2 }] }]"
        size="large"
        placeholder="请再次输入新密码"
      >
        <a-icon slot="prefix" type="lock" />
      </a-input>
    </a-form-item>

    <div class="tip">* 请输入八位以上复杂密码</div>

    <a-button style="margin-top: 24px" size="large" type="primary" block html-type="submit">确认</a-button>
  </a-form>
</template>

<script>
// import { updatePassword } from "@/api/login";
import { mapState } from "vuex";
export default {
  data() {
    return {
      form: this.$form.createForm(this)
    };
  },

  computed: {
    ...mapState("login", ["user"])
  },

  methods: {
    // 自定义校验规则
    validator1(rule, value) {
      let testResult = /[0-9]+/.test(value) && /[a-zA-Z]+/.test(value);
      //  &&
      // /[A-Z]+/.test(value) &&
      // /[!@#$%^&*()\\|=+\-{}.?/,><;:_~]+/.test(value);

      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入新密码！");
        } else if (value.length < 8) {
          reject("密码不小于8位！");
        } else if (!testResult) {
          reject("密码需包含字母和数字！");
        } else {
          resolve();
        }
      });
    },

    validator2(rule, value) {
      console.log("value ", value);
      const password = this.form.getFieldValue("password");

      return new Promise((resolve, reject) => {
        if (!value) {
          reject("请输入新密码！");
        } else if (value !== password) {
          reject("密码不一致！");
        } else {
          resolve();
        }
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);

          // updatePassword({
          //   password: this.$encrypt.encrypt(values.password),
          //   uuid: this.user.userId,
          // }).then(() => {
          //   this.$message.success("修改成功，请重新登录！");
          //   this.$emit("toggle", "normal");
          // });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.tip {
  color: #fff;
  margin-top: 0;
}
</style>